import { Chain } from '@wagmi/core'

// export const TRITON = {
//   id: 88002,
//   name: 'Proteus',
//   network: 'proteus',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'Zebec',
//     symbol: 'ZBC',
//   },
//   rpcUrls: {
//     public: { http: ['https://api.proteus.nautchain.xyz/solana'] },
//     default: { http: ['https://api.proteus.nautchain.xyz/solana'] },
//   },
//   blockExplorers: {
//     etherscan: { name: 'NautScan', url: 'https://proteus.nautscan.com/' },
//     default: { name: 'NautScan', url: 'https://proteus.nautscan.com/' },
//   },
//   contracts: {
//     multicall3: {
//       address: '0xa84A3DfC2A8737748f9a45910ee475c0722C9726',
//     },
//   },
// } as const satisfies Chain

export const Nautlis = {
  id: 22222,
  name: 'Nautilus Mainnet',
  network: 'nautilus',
  nativeCurrency: {
    decimals: 18,
    name: 'Zebec',
    symbol: 'ZBC',
  },
  rpcUrls: {
    public: { http: ['https://api.nautilus.nautchain.xyz/'] },
    default: { http: ['https://api.nautilus.nautchain.xyz/'] },
  },
  blockExplorers: {
    etherscan: { name: 'NautScan', url: 'https://nautscan.com' },
    default: { name: 'NautScan', url: 'https://nautscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xe4Da9E988917bAD08FBe6B228bAb107Be5C57181',
    },
  },
} as const satisfies Chain

// TRITON
// export const TRITON = {
//   id: 91002,
//   name: 'Triton',
//   network: 'triton',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'Zebec',
//     symbol: 'ZBC',
//   },
//   rpcUrls: {
//     public: { http: ['https://triton.api.nautchain.xyz'] },
//     default: { http: ['https://triton.api.nautchain.xyz'] },
//   },
//   blockExplorers: {
//     etherscan: { name: 'NautScan', url: 'https://triton.nautscan.com' },
//     default: { name: 'NautScan', url: 'https://triton.nautscan.com' },
//   },
//   contracts: {
//     multicall3: {
//       address: '0xa84A3DfC2A8737748f9a45910ee475c0722C9726',
//     },
//   },
// } as const satisfies Chain

// BSC
// export const TRITON = {
//   id: 97,
//   name: 'BSC Testnet',
//   network: 'BSC Testnet',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'bnb',
//     symbol: 'BNB',
//   },
//   rpcUrls: {
//     public: { http: ['https://data-seed-prebsc-1-s1.binance.org:8545/'] },
//     default: { http: ['https://data-seed-prebsc-1-s1.binance.org:8545/'] },
//   },
//   blockExplorers: {
//     etherscan: { name: 'NautScan', url: 'https://testnet.bscscan.com' },
//     default: { name: 'NautScan', url: 'https://testnet.bscscan.com' },
//   },
//   contracts: {
//     multicall3: {
//       address: '0xa84A3DfC2A8737748f9a45910ee475c0722C9726',
//     },
//   },
// } as const satisfies Chain
