import React from 'react'
import Slider from 'react-slick'

import PropTypes from 'prop-types'
import { useWindowDimensions } from '../../utils/utils'
import styles from './Carousel.module.scss'

const arrayIndex = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
const Carousel = (props) => {
  const { choice } = props
  const { width } = useWindowDimensions()
  const numOfSlidesToShow = () => {
    let res = 4
    if (width < 768) {
      res = 1
    } else if (width < 1024) {
      res = 3
    }
    return res
  }
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: numOfSlidesToShow(),
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  }

  return (
    <div>
      <Slider {...settings}>
        {arrayIndex.map((index) => (
          <div key={index} className={styles.carousel}>
            <img
              src={`/static/images/didchoice/${choice}/${choice}-${index}.png`}
              alt={`${choice}${index}`}
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

Carousel.propTypes = {
  choice: PropTypes.string,
}

export default Carousel
