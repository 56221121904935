import React from 'react'
import PixelButton from '../../UI/PixelButton/PixelButton'
import { useWallet } from '../../../store/wallet-context'
import styles from './LaunchProfile.module.scss'
import Claim from '../../Claim/Claim'
import Faucet from '../../Faucet/Faucet'

const LaunchProfile = (param) => {
  const { userData, nft1, nft2, nft3, eligibleStateText } = param
  const { isConnected, eligibleState } = useWallet()
  return (
    <div className={styles.launchProfile}>
      <img src="/static/images/didchoice/SSR/SSR-1.png" alt="defaultHeaderIcon" />
      {!isConnected && <PixelButton />}
      {isConnected && (
        <div className={styles.loginInfo}>
          <Faucet userData={userData} />
          <br />
          <div>{eligibleState ? eligibleStateText : 'Uh-Oh! You are not eligible!'}</div>
          {/* <p>Detail-&gt;</p> */}
          <Claim userData={userData} nft1={nft1} nft2={nft2} nft3={nft3} />
        </div>
      )}
    </div>
  )
}

export default LaunchProfile
