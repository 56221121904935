import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'

import styles from './CustomButton.module.scss'

const CustomButton = (props) => {
  const { className, children, onClick, secondary, loading, noclick } = props
  return (
    <div
      className={classNames(
        styles.CustomButton,
        className,
        { [styles.secondary]: secondary },
        { [styles.loading]: loading },
        { [styles.noclick]: noclick }
      )}
      onClick={noclick ? null : onClick}
    >
      {children}
      {loading && (
        <>
          &nbsp;
          <LoadingOutlined />
        </>
      )}
    </div>
  )
}

CustomButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  noclick: PropTypes.bool,
}

CustomButton.defaultProps = {
  className: '',
  children: null,
  onClick: () => undefined,
  secondary: false,
  loading: false,
  bool: false,
}

export default CustomButton
