import React, { useEffect, useState } from 'react'
import { useWallet } from '../../store/wallet-context'
import styles from './Faucet.module.scss'
import axios from 'axios'
import { useToast } from '@chakra-ui/react'

const signData = {
  msg: 'I LOVE NAUTILUS',
}
let isLock = false

const Faucet = (param) => {
  const { userData } = param
  const {
    signMessage,
    userWallet: { walletAddress: address },
    balance,
    refetchBalance,
  } = useWallet()
  const toast = useToast()

  const [isShow, setIsShow] = useState(false)
  useEffect(() => {
    setIsShow(balance?.formatted < 1)
  }, [balance])

  const query = async () => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/did/faucet`, {
        msg: signData.msg,
        sign: signData.sign,
      })

      if (result.data.msg.includes('wait...')) {
        toast({
          title: result.data.msg,
          status: 'info',
          duration: 9000,
          isClosable: true,
        })
        setTimeout(query, 3000)
      } else {
        // toast.closeAll()
        toast({
          title: result.data.msg,
          status: result.data.error ? 'error' : 'success',
          duration: 9000,
          isClosable: true,
        })
      }
    } catch (e) {
      toast({
        title: e?.response?.data?.error || e.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      isLock = false
    }
    isLock = false
  }

  const onClick = async () => {
    if (isLock) {
      return
    }
    isLock = true
    try {
      // if (signData.address !== address) {
      signData.sign = await signMessage({ message: signData.msg })
      signData.address = address
      // }

      query()
    } catch (e) {
      toast({
        title: "Wallet Signature Error - You didn't sign the message correctly",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      isLock = false
    }
  }

  return (
    <>
      {isShow && userData?.is_faucet === true && userData?.tx_faucet === null ? (
        <p className={styles.button} onClick={onClick}>
          Get ZBC -&gt;
        </p>
      ) : null}
    </>
  )
}

export default Faucet
