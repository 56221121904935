import React from 'react'
import Header from './components/Header/Header'
import { Outlet } from 'react-router-dom'

import './App.css'
const App = () => (
  <div className="App">
    <Header />
    <Outlet />
  </div>
)

export default App
