import React, { useEffect, useState } from 'react'
import { useWallet } from '../../store/wallet-context'
import styles from './Claim.module.scss'
import axios from 'axios'
import { useToast } from '@chakra-ui/react'
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'
import ERC721 from '../../abi/ERC721.json'
import { ethers } from 'ethers'

let isLock = false
let writeLock = false
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
let mintNFT = 0
let refetchBalanceLock = true

const Claim = (param) => {
  const { userData, nft1, nft2, nft3, refetchBalance } = param
  const {
    userWallet: { walletAddress: address },
    balance,
  } = useWallet()
  const [signData, setSignData] = useState(null)
  const toast = useToast()
  const [minted, setMinted] = useState([false, false, false])

  // MINT NFT

  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    address: process.env.REACT_APP_CONTRACT_ADDRESS_MANAGER,
    abi: ERC721,
    functionName: 'mintNautDid',
    args: signData,
    enabled: Boolean(signData),
  })

  const { data, error, isError, write } = useContractWrite(config)

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })

  useEffect(() => {
    if (refetchBalance && !refetchBalanceLock) {
      refetchBalanceLock = true
      toast({
        title: 'waiting for transaction...',
        status: 'info',
        duration: 9000,
        isClosable: true,
      })
      refetchBalance?.()
      console.log('refetchBalance', !!refetchBalance, mintNFT, minted.filter((item) => item).length)
      if (mintNFT < 3 && mintNFT === minted.filter((item) => item).length) {
        setTimeout(() => {
          refetchBalanceLock = false
        }, 1500)
      } else {
        toast({
          title: 'Successfully minted your NFT!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      }
    }
  }, [refetchBalance, refetchBalanceLock, param, minted])

  useEffect(() => {
    if (signData && write && !writeLock) {
      writeLock = true
      console.log('signData', signData)
      console.log(write)
      write?.()
      mintNFT = minted.filter((item) => item).length
      // if (signData?.[0]?.tokenId >= 2 * 100000) {
      //   setIsLockSSR(true)
      // } else if (signData?.[0]?.tokenId >= 1 * 100000) {
      //   setIsLockSR(true)
      // } else if (signData?.[0]?.tokenId >= 0 * 100000) {
      //   setIsLockR(true)
      // }
    }
  }, [signData, write])

  const [isLockR, setIsLockR] = useState(false)
  const [isLockSR, setIsLockSR] = useState(false)
  const [isLockSSR, setIsLockSSR] = useState(false)
  useEffect(() => {
    if (signData && writeLock && (isPrepareError || isError || isSuccess)) {
      writeLock = false
      if (isSuccess) {
        mintNFT = minted.filter((item) => item).length
        refetchBalanceLock = false
        // if (signData?.[0]?.tokenId >= 2 * 100000) {
        //   setIsLockSSR(true)
        // } else if (signData?.[0]?.tokenId >= 1 * 100000) {
        //   setIsLockSR(true)
        // } else if (signData?.[0]?.tokenId >= 0 * 100000) {
        //   setIsLockR(true)
        // }
      }
      setSignData(null)
      console.log('mint update', mintNFT, isPrepareError, isError, isSuccess)
    }
  }, [isPrepareError, isError, isSuccess])

  // useEffect(() => {
  //   if ((prepareError || error)?.message && signData.length > 1) {
  //     toast({
  //       title: (prepareError || error)?.message,
  //       status: 'error',
  //       duration: 9000,
  //       isClosable: true,
  //     })
  //   }
  // }, [prepareError, error])

  const onClick = async (quality) => {
    if (isLock) {
      return
    }
    isLock = true
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/did/mintSignDigest`,
        {
          address,
          quality,
        }
      )
      console.log('data', data)
      setSignData([data.claimParam, data.signature])
    } catch (e) {
      if (quality === 'SSR') {
        setIsLockSSR(false)
      } else if (quality === 'SR') {
        setIsLockSR(false)
      } else if (quality === 'R') {
        setIsLockR(false)
      }
      toast({
        title: e?.response?.data?.error || e.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      console.log('eee', e)
    }
    await sleep(1000)
    isLock = false
  }

  // NFT DATA
  useEffect(() => {
    let _minted = [false, false, false]
    const checkMinted = (nft) => {
      if (nft > 2 * 100000) {
        _minted[2] = true
      } else if (nft > 1 * 100000) {
        _minted[1] = true
      } else if (nft > 0 * 100000) {
        _minted[0] = true
      }
    }
    checkMinted(nft1)
    checkMinted(nft2)
    checkMinted(nft3)
    setMinted(_minted)
    console.log('nft check', nft1, nft2, nft3, _minted)
  }, [nft1, nft2, nft3])

  return (
    <div className={styles.claim_area}>
      {balance?.value?.eq(ethers.utils.parseEther('0')) &&
      userData?.is_faucet === true &&
      userData?.tx_faucet !== null
        ? 'waiting for ZBC faucet transaction.'
        : null}
      {balance?.value?.lt(ethers.utils.parseEther('0.01'))
        ? 'you need ZBC to proceed with the claim.'
        : null}
      {balance?.value?.gt(ethers.utils.parseEther('0.01')) && (
        <div>
          {userData?.is_ssr && !minted[2] && !isLockSSR ? (
            <button onClick={() => onClick('SSR')}>Claim SSR NFT</button>
          ) : null}
          {userData?.is_sr && !minted[1] && !isLockSR ? (
            <button onClick={() => onClick('SR')}>
              {userData?.tx_sr === null ? 'NO MORE SR' : 'Claim SR NFT'}
            </button>
          ) : null}
          {userData?.is_r && !minted[0] && !isLockR ? (
            <button onClick={() => onClick('R')}>
              {userData?.tx_sr === null ? 'NO MORE R' : 'Claim R NFT'}
            </button>
          ) : null}
        </div>
      )}

      <br />
      {isSuccess && <div>Successfully minted your NFT!</div>}
      {(isPrepareError || isError) && (
        <div style={{ wordBreak: 'break-all', color: '#ffeb34', width: '100%' }}>
          Error: {(prepareError || error)?.message}
        </div>
      )}
    </div>
  )
}

export default Claim
