import { ethers } from 'ethers'
import { useEffect, useState } from 'react'

export const truncAddress = (addr: string) => {
  if (addr && addr.length === 42 && addr !== ethers.constants.AddressZero) {
    return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`
  }
  return '-'
}

export const truncateNumber = (str: string, maxDecimalDigits: number) => {
  if (str.includes('.')) {
    const parts = str.split('.')
    return parts[0] + '.' + parts[1].slice(0, maxDecimalDigits)
  }
  return str
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
