import React from 'react'

import PropTypes from 'prop-types'
import styles from './SupplyDetail.module.scss'
const SupplyDetail = (props) => {
  const { imgSrc, cnt, altInfo } = props
  return (
    <div className={styles.supplyDetail}>
      <img src={imgSrc} alt={altInfo} />
      <p>&nbsp;x {cnt}</p>
    </div>
  )
}

SupplyDetail.propTypes = {
  imgSrc: PropTypes.string,
  cnt: PropTypes.number,
  altInfo: PropTypes.string,
}
export default SupplyDetail
