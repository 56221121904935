import React from 'react'
import { useWallet } from '../../../store/wallet-context'
import classNames from 'classnames'
import styles from './PixelButton.module.scss'
import { truncAddress } from '../../../utils/utils'
import PropTypes from 'prop-types'

const PixelButton = (props) => {
  const { className, onClick } = props
  const { connect, disconnect, userWallet, isConnected } = useWallet()
  const actionFunc = isConnected ? disconnect : connect
  return (
    <div
      className={classNames(styles.pixelButton, className)}
      onClick={() => {
        actionFunc()
        if (onClick) {
          onClick()
        }
      }}
    >
      <img src="/static/images/launchButtonImage.png" alt="button" />
      <p>{isConnected ? truncAddress(userWallet.walletAddress) : 'Connect Wallet'}</p>
    </div>
  )
}

PixelButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default PixelButton
