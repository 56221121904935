import React from 'react'
import { WagmiConfig, createClient, configureChains } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
// import { TRITON } from '../constants/chain'
import { Nautlis } from '../constants/chain'

const { provider } = configureChains(
  [Nautlis],
  [
    jsonRpcProvider({
      rpc: () => {
        const {
          rpcUrls: {
            default: {
              http: [http],
            },
          },
        } = Nautlis

        return {
          http,
        }
      },
    }),
  ]
)

const client = createClient({
  autoConnect: true,
  provider,
})

const WagmiProvider = ({ children }: { children: React.ReactNode }) => (
  <WagmiConfig client={client}>{children}</WagmiConfig>
)

export default WagmiProvider
