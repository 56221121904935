import React, { useEffect, useState } from 'react'
import MainContainer from '../components/UI/MainContainer/MainContainer'

import classNames from 'classnames'

import CopyToClipboard from 'react-copy-to-clipboard'
import { message, Button } from 'antd'

import LaunchIntro from '../components/LaunchInfo/LaunchIntro/LaunchIntro'
import LaunchProfile from '../components/LaunchInfo/LaunchProfile/LaunchProfile'
import Carousel from '../components/Carousel/Carousel'
import CustomButton from '../components/UI/CustomButton/CustomButton'
import PixelButton from '../components/UI/PixelButton/PixelButton'

import { useWallet } from '../store/wallet-context'

import styles from './Home.module.scss'

import Faucet from '../components/Faucet/Faucet'
import Claim from '../components/Claim/Claim'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { useContractRead } from 'wagmi'
import ERC721 from '../abi/ERC721.json'
import { ethers } from 'ethers'

const ssrCnt = 5000
const srCnt = 30000
const rCnt = 65000
const totalSupply = 100000
// const soldCnt = 80000
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const Home = () => {
  const {
    didChoice,
    setDidChoice,
    referralLink,
    setReferralLink,
    eligibleState,
    setEligibleState,
    isConnected,
    userWallet: { walletAddress: address },
  } = useWallet()
  const toast = useToast()
  const [inviteCode, setInviteCode] = useState('')
  const [soldCnt, setSoldCnt] = useState(null)
  const [userData, setUserData] = useState(null)
  const { ref } = useParams()
  const setDefaultsStatus = () => {
    setEligibleState(false)
    setDidChoice('SSR')
    setReferralLink(null)
    setUserData({})
  }

  // 總銷售量
  const queryCount = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/did/mintQueryAll`)
      setSoldCnt(data)
    } catch (e) {
      await sleep(5 * 1000)
      queryCount()
    }
  }
  let queryCountLock = false
  useEffect(() => {
    if (!queryCountLock) {
      queryCountLock = true
      queryCount()
    }
  }, [])

  // 用戶資料
  const query = async (ref) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/did/userQuery`, {
        address,
        ...(ref && { ref }),
      })
      setUserData(data)
      if (data.is_ssr) {
        setEligibleState(true)
        setDidChoice('SSR')
      } else if (data.is_sr) {
        setEligibleState(true)
        setDidChoice('SR')
      } else if (data.is_r) {
        setEligibleState(true)
        setDidChoice('R')
      } else {
        setEligibleState(false)
        setDidChoice('SSR')
      }
      if (data.code) {
        setReferralLink(`${location.origin}/referral/${data.code}`)
      } else {
        setReferralLink(null)
      }
      return data
    } catch (e) {
      setDefaultsStatus()
    }
    return null
  }
  useEffect(() => {
    if (isConnected && address) {
      query(ref)
    }
  }, [isConnected, address])

  // 邀請碼檢查
  const checkInviteCode = async () => {
    const q = await query(inviteCode)
    if (q && q?.ref === inviteCode) {
      toast({
        title: 'Congratulations! there’s a claimable R NFT',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } else {
      toast({
        title: 'Incorrect invitation code',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  // NFT 背包
  const { data: nftBalanceOf, refetch: refetchBalance } = useContractRead({
    address: process.env.REACT_APP_CONTRACT_ADDRESS_ERC721,
    abi: ERC721,
    functionName: 'balanceOf',
    args: [address],
    enabled: Boolean(address),
  })
  const [triggerNft, setTriggerNft] = useState([])
  const { data: nft1 } = useContractRead({
    address: process.env.REACT_APP_CONTRACT_ADDRESS_ERC721,
    abi: ERC721,
    functionName: 'tokenOfOwnerByIndex',
    args: [address, triggerNft[0]],
    enabled: Boolean(triggerNft[0]),
  })
  const { data: nft2 } = useContractRead({
    address: process.env.REACT_APP_CONTRACT_ADDRESS_ERC721,
    abi: ERC721,
    functionName: 'tokenOfOwnerByIndex',
    args: [address, triggerNft[1]],
    enabled: Boolean(triggerNft[1]),
  })
  const { data: nft3 } = useContractRead({
    address: process.env.REACT_APP_CONTRACT_ADDRESS_ERC721,
    abi: ERC721,
    functionName: 'tokenOfOwnerByIndex',
    args: [address, triggerNft[2]],
    enabled: Boolean(triggerNft[2]),
  })
  useEffect(() => {
    let _triggerNft = []
    for (let i = 0; i < nftBalanceOf; i++) {
      _triggerNft.push(ethers.BigNumber.from(i))
    }
    setTriggerNft(_triggerNft)
    console.log('_triggerNft', _triggerNft, nftBalanceOf?.toNumber())
  }, [nftBalanceOf])

  // NFT Metadata
  // const { data: nft4 } = useContractRead({
  //   address: process.env.REACT_APP_CONTRACT_ADDRESS_ERC721,
  //   abi: ERC721,
  //   functionName: 'tokenURI',
  //   args: [nft1],
  //   enabled: Boolean(nft1),
  // })

  // NFT Metadata Images
  const [nftMetadata, setNftMetadata] = useState([null, null, null])
  const qualityImg = (nftId) => {
    let img = process.env.REACT_APP_METADATA_IMAGE_PATH
    let quality = ''
    if (nftId >= 2 * 100000) {
      quality = 'SSR'
      img += `${quality}/${nftId.sub(2 * 100000).toNumber()}.png`
    } else if (nftId >= 1 * 100000) {
      quality = 'SR'
      img += `${quality}/${nftId.sub(1 * 100000).toNumber()}.png`
    } else {
      quality = 'R'
      img += `${quality}/${nftId}.png`
    }
    return {
      quality,
      img,
      nftId: nftId.toNumber(),
    }
  }
  useEffect(() => {
    let _nftMetadata = [null, null, null]
    if (nft1) {
      _nftMetadata[0] = qualityImg(nft1)
    }
    if (nft2) {
      _nftMetadata[1] = qualityImg(nft2)
    }
    if (nft3) {
      _nftMetadata[2] = qualityImg(nft3)
    }
    console.log('_nftMetadata', _nftMetadata, address)
    setNftMetadata(_nftMetadata)
  }, [nft1, nft2, nft3])

  const [eligibleStateText, setEligibleStateText] = useState('Wow! You are eligible.')
  useEffect(() => {
    let isClaimAll = false
    let countClaim = 0
    let countEligible = 0
    userData?.is_r && countEligible++
    userData?.is_sr && countEligible++
    userData?.is_ssr && countEligible++
    nft1 && countClaim++
    nft2 && countClaim++
    nft3 && countClaim++
    if (countClaim >= countEligible) {
      isClaimAll = true
    }
    console.log('isClaimAll', countClaim, countEligible, isClaimAll)
    if (isClaimAll) {
      setEligibleStateText('You have claimed!')
    } else {
      setEligibleStateText('Wow! You are eligible.')
    }
  }, [eligibleState, nftMetadata])

  return (
    <div className={styles.Home}>
      <section className={styles.banner} style={{ backgroundImage: 'url(/static/images/bg.png)' }}>
        {/* <img className={styles.bannerBg} src="/static/images/bg.png" alt="bg" /> */}
        <img
          className={styles.bannerBottom}
          src="/static/images/mainBottomGroup.png"
          alt="main Bottom Group"
        />
      </section>
      <section
        className={styles.didWrapper}
        style={{
          backgroundImage: 'url(/static/images/launchInfo/launchBg.png)',
        }}
      >
        <MainContainer className={styles.launchInfo}>
          <LaunchIntro
            totalSupply={totalSupply}
            ssrCnt={ssrCnt}
            srCnt={srCnt}
            rCnt={rCnt}
            soldCnt={soldCnt}
          />
          <LaunchProfile
            userData={userData}
            nft1={nft1}
            nft2={nft2}
            nft3={nft3}
            eligibleStateText={eligibleStateText}
          />
        </MainContainer>
        <MainContainer className={styles.claim}>
          <h1 className={styles.claimTitle}>Claim your Naut DID now!</h1>
          <br />
          <div className={styles.choiceGroup}>
            <Button
              onClick={() => setDidChoice('SSR')}
              className={classNames(styles.choiceButton, {
                [styles.chosenBorder]: didChoice === 'SSR',
              })}
            >
              <img src="/static/images/SSRIcon.png" alt="SSR" />
            </Button>
            <Button
              onClick={() => setDidChoice('SR')}
              className={classNames(styles.choiceButton, {
                [styles.chosenBorder]: didChoice === 'SR',
              })}
            >
              <img src="/static/images/SRIcon.png" alt="SR" />
            </Button>
            <Button
              onClick={() => setDidChoice('R')}
              className={classNames(styles.choiceButton, {
                [styles.chosenBorder]: didChoice === 'R',
              })}
            >
              <img src="/static/images/RIcon.png" alt="R" />
            </Button>
          </div>
          <br />
          <Carousel choice={didChoice} />
          <br />
          {!isConnected ? (
            <PixelButton />
          ) : (
            <div className={styles.claimLoginDetail}>
              {eligibleState ? (
                <>
                  <p>{eligibleStateText}</p>
                  <br />
                  <div>
                    <a
                      href="https://horizon.zebec.io/#/participate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get Zepoch -&gt;
                    </a>
                    <Faucet userData={userData} />
                  </div>{' '}
                  <Claim
                    userData={userData}
                    nft1={nft1}
                    nft2={nft2}
                    nft3={nft3}
                    refetchBalance={refetchBalance}
                  />
                </>
              ) : (
                <p>
                  Uh-Oh! You are not eligible!
                  <br />
                  <br />
                  <input
                    className={styles.inviteInput}
                    type="text"
                    value={inviteCode}
                    placeholder={'Type invitation code'}
                    onChange={(e) => {
                      setInviteCode(e.target.value)
                    }}
                    maxLength={5}
                  />
                  <button className={styles.inviteButton} onClick={checkInviteCode}>
                    Check
                  </button>
                  {/* <br />
                  <br />
                  <br />
                  Historical trion users: at least two swap transactions, or acted as an LP before 1
                  January 2023.
                  <br />
                  <br />
                  Users who participated in the Nautilus Chain Testnet before 30 May 2023.
                  <br />
                  <br />
                  Users who participated in the Nautilus Chain x Galxe Campaign.
                  <br />
                  <br />
                  Don’t worry! There will be plenty more opportunities to earn ZBC in the coming
                  months! */}
                </p>
              )}
            </div>
          )}
        </MainContainer>
        {isConnected && referralLink && (
          <MainContainer className={styles.referralInfo}>
            <div className={styles.referralSpaceHolder} />
            <div className={styles.referralContent}>
              <h1>Referral</h1>
              <br />
              <p>
                Get users who are eligible to receive SSR and SR, <br />
                share and invite friends mint R NFT.
              </p>

              <br />

              <div className={styles.referralDetail}>
                <div className={styles.referralLink}>{referralLink}</div>
                <br />
                <CopyToClipboard
                  text={referralLink}
                  onCopy={() => {
                    message.success('Referral Link Copied')
                  }}
                >
                  <CustomButton className={styles.referralLinkCopy}>
                    Copy referral link
                  </CustomButton>
                </CopyToClipboard>
              </div>
            </div>
          </MainContainer>
        )}
        <MainContainer>
          {isConnected && nft1 ? (
            <div className={styles.footer}>
              <div>Naut DID Claimed</div>
              <div>
                {nftMetadata.map((item, index) =>
                  item ? (
                    <div className={styles.nftItem} key={index}>
                      <img src={item.img} key={index} />
                      <div className={styles.nftLabel}>
                        TYPE: {item.quality}
                        <br />
                        TokeId: {item.nftId}
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            </div>
          ) : null}
        </MainContainer>
      </section>
    </div>
  )
}

export default Home
