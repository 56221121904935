import React from 'react'
import ReactDOM from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import WagmiProvider from './store/wagmi-context'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { WalletContextProvider } from './store/wallet-context'

import App from './App'
import Home from './pages/Home'

import './index.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import "./i18n";
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'referral/:ref',
        element: <Home />,
      },
    ],
  },
])
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <WagmiProvider>
        <WalletContextProvider>
          <RouterProvider router={router} />
        </WalletContextProvider>
      </WagmiProvider>
    </ChakraProvider>
  </React.StrictMode>
)
