import React from 'react'

import { truncAddress, useWindowDimensions } from '../../utils/utils'
import { useWallet } from '../../store/wallet-context'
import CustomButton from '../UI/CustomButton/CustomButton'
import styles from './Header.module.scss'

const Header = () => {
  const { width } = useWindowDimensions()
  const MobileView = width < 768
  const { connect, disconnect, userWallet, isConnected } = useWallet()
  return (
    <div className={styles.Header}>
      <img src="/static/images/textLogo.png" alt="textLogo" />
      {!MobileView && (
        <CustomButton className={styles.connectButton} onClick={isConnected ? disconnect : connect}>
          {isConnected ? truncAddress(userWallet.walletAddress) : 'Connect Wallet'}
        </CustomButton>
      )}
    </div>
  )
}

export default Header
