import React, { createContext, useContext, useMemo, useState, useEffect } from 'react'
import { useAccount, useBalance, useConnect, useDisconnect } from 'wagmi'
import { InjectedConnector, FetchBalanceResult, signMessage } from '@wagmi/core'
import { useIsMounted } from '../hooks/useIsMounted'
// import { TRITON } from '../constants/chain'
import { Nautlis } from '../constants/chain'
import { ethers } from 'ethers'
import { MIN_BALANCE } from '../constants/misc'

export interface UserWallet {
  walletAddress: `0x${string}` | undefined
  walletBalance: FetchBalanceResult | undefined
}

export const USER_WALLET_INIT: UserWallet = {
  walletAddress: undefined,
  walletBalance: undefined,
}

interface WalletContextValue {
  isConnected: boolean
  userWallet: UserWallet
  connect: any
  disconnect: any
  hasBalance: boolean
}

const WalletContext = createContext<WalletContextValue>({
  isConnected: false,
  userWallet: USER_WALLET_INIT,
  connect: undefined,
  disconnect: undefined,
  hasBalance: false,
})

export const useWallet = () => useContext(WalletContext)

const useWalletProvider = () => {
  const [didChoice, setDidChoice] = useState('SSR')
  const [referralLink, setReferralLink] = useState(null)
  const [eligibleState, setEligibleState] = useState(false)
  const isMounted = useIsMounted()
  const { address, isConnected } = useAccount()
  const { data: balance, refetch: refetchBalance } = useBalance({
    address,
    chainId: Nautlis.id,
    watch: true,
  })
  const { connect } = useConnect({
    connector: new InjectedConnector({ chains: [Nautlis] }),
    chainId: Nautlis.id,
  })
  const { disconnect } = useDisconnect()

  return useMemo(() => {
    const value = {
      isConnected: false,
      userWallet: USER_WALLET_INIT,
      connect: connect,
      disconnect: disconnect,
      hasBalance: false,
      signMessage: signMessage,
      didChoice,
      setDidChoice,
      referralLink,
      setReferralLink,
      eligibleState,
      setEligibleState,
      refetchBalance,
      balance,
    }
    if (isMounted()) {
      return {
        ...value,
        isConnected,
        userWallet: { walletAddress: address, walletBalance: balance },
        hasBalance: !!balance?.value.gt(ethers.utils.parseUnits(MIN_BALANCE, balance?.decimals)),
      }
    }
    return value
  }, [
    isConnected,
    connect,
    disconnect,
    isMounted,
    address,
    balance,
    signMessage,
    didChoice,
    referralLink,
    eligibleState,
  ])
}

export const WalletContextProvider = ({ children }: { children: React.ReactNode }) => {
  const context = useWalletProvider()
  return <WalletContext.Provider value={context}>{children}</WalletContext.Provider>
}
