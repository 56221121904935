import React from 'react'

import CustomButton from '../../UI/CustomButton/CustomButton'
import SupplyDetail from './SupplyDetail/SupplyDetail'
import PropTypes from 'prop-types'

import styles from './LaunchIntro.module.scss'

const LaunchIntro = (props) => {
  const { totalSupply, ssrCnt, srCnt, rCnt, soldCnt } = props
  const supply = [
    { index: 1, level: 'SSR', count: ssrCnt },
    { index: 2, level: 'SR', count: srCnt },
    { index: 3, level: 'R', count: rCnt },
  ]
  const progress = supply.reduce(
    (obj, cur) => ({
      ...obj,
      [cur.level]:
        ((soldCnt?.filter((soldCntItem) => soldCntItem.key === `sign_${cur.level}`)?.[0]['count'] ||
          0) /
          cur.count) *
        100,
    }),
    {}
  )
  return (
    <div className={styles.launchIntro}>
      {/* <CustomButton className={styles.launchTime} noclick>
        Aug 24, 2023 at 10:00am
      </CustomButton> */}
      <h2> Nautilus Chain Layer3 DID launch</h2>
      <br />
      <h6>
        NautDID is the key to your future decentralized, digital identity, opening the door to a
        future that is more free and fair. Like a digital mask, NautDiD is your passport to all
        corners of the decentralized web and metaverse, allowing you to interact and prove your
        credentials – without revealing who you are or giving up all your personal data.
      </h6>
      <br />
      <h6>
        Total supply: <span>{totalSupply}</span> (First-come, first-served) o{' '}
      </h6>
      <br />
      <div className={styles.supplyDetail}>
        {supply.map((item, i) => (
          <div className={styles.supplyDetailItem} key={i}>
            <SupplyDetail
              imgSrc={`/static/images/${item.level}Icon.png`}
              key={item.index}
              altInfo={item.level}
              cnt={item.count}
            />
            <div className={styles.progress}>
              {/* <p>Progress</p> */}
              {/* <Progress
          percent={progress}
          showInfo={false}
          strokeColor={"rgba(62, 219, 240, 1)"}
          trailColor={"rgba(62, 112, 240, 1)"}
        /> */}
              <div className={styles.progressDisplay}>
                <div
                  className={styles.onGoingBar}
                  style={{
                    width: `${progress[item.level]}%`,
                  }}
                >
                  <img src="/static/images/onGoing.png" alt="onGoing" />
                </div>
                <div className={styles.totalBar}>
                  <img src="/static/images/total.png" alt="total" />
                </div>
              </div>
              <div className={styles.progressDetail}>
                <p>{`${progress[item.level].toFixed(3)}%`}</p>
                <p>
                  {`${
                    soldCnt?.filter((soldCntItem) => soldCntItem.key === `sign_${item.level}`)?.[0][
                      'count'
                    ] || 0
                  }/`}
                  <span>{item.count}</span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

LaunchIntro.propTypes = {
  totalSupply: PropTypes.number,
  ssrCnt: PropTypes.number,
  srCnt: PropTypes.number,
  rCnt: PropTypes.number,
  soldCnt: PropTypes.number,
}

export default LaunchIntro
